import $ from 'jquery';
import Flickity from "flickity";

/* global jQuery: true*/
// the semi-colon before function invocation is a safety net against concatenated
// scripts and/or other plugins which may not be closed properly.
;(function($, window, document, undefined) {
	"use strict";
	// undefined is used here as the undefined global variable in ECMAScript 3 is
	// mutable (ie. it can be changed by someone else). undefined isn't really being
	// passed in so we can ensure the value of it is truly undefined. In ES5, undefined
	// can no longer be modified.

	// window and document are passed through as local variable rather than global
	// as this (slightly) quickens the resolution process and can be more efficiently
	// minified (especially when both are regularly referenced in your plugin).

	// Create the defaults once
	var pluginName = "projectsSlideshow",
		defaults = {};

	// The actual plugin constructor

	function Plugin(element, options) {
		this.element = element;
		this.$element = $(element);
		this.options = $.extend({}, defaults, options);
		this._defaults = defaults;
		this._name = pluginName;
		this.flkty = null;
		this.init();
	}

	$.extend(Plugin.prototype, {
		init: function() {
		  this.flkty = new Flickity(this.element, {
		    cellAlign: "left",
		    contain: true,
		    wrapAround: true
		  });
    },
	});


	// A really lightweight plugin wrapper around the constructor,
	// preventing against multiple instantiations
	$.fn[pluginName] = function(option) {
		var args = arguments,
				result;

		this.each(function() {
			var $this = $(this),
				data = $.data(this, "plugin_" + pluginName),
				options = typeof option === "object" && option;
			if (!data) {
				$this.data("plugin_" + pluginName, (data = new Plugin(this, options)));
			}
			// if first argument is a string, call silimarly named function
			// this gives flexibility to call functions of the plugin e.g.
			//   - $(".dial").plugin("destroy");
			//   - $(".dial").plugin("render", $(".new-child"));
			if (typeof option === "string") {
				result = data[option].apply(data, Array.prototype.slice.call(args, 1));
			}
		});

		// To enable plugin returns values
    return result || this;
	};

})(jQuery, window, document);

$(function() {
  var selector;
  selector = ".projects_slideshow";
  $(selector).projectsSlideshow();
  new MutationObserver(function(mutations) {
    mutations.forEach(function(mutation) {
      $(selector).projectsSlideshow();
    });
  }).observe(document.documentElement, {
    childList: true,
    subtree: true
  });
});
